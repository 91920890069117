import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";

import AppTheme from './theme/AppTheme';
import CssBaseline from '@mui/material/CssBaseline';

import {
  chartsCustomizations,
  dataGridCustomizations,
} from './theme/customizations';

import NotFoundPage from './pages/NotFoundPage';
import PrivateRoute from './pages/PrivateRoute';
import SignInCallbackPage from './pages/SignInCallbackPage';
import HomePage from './pages/HomePage';
import ScheduleManagementPage from './pages/ScheduleManagementPage';
import ScheduleDetailsPage from './pages/ScheduleDetailsPage';
import ApiKeysPage from './pages/ApiKeysPage';

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations
};

const isDev = process.env.NODE_ENV === 'development';

const App: React.FC = (props: { disableCustomTheme?: boolean }) => {
  return (
      <AppTheme {...props} themeComponents={xThemeComponents}>
        <CssBaseline enableColorScheme/>
        <Router>
          <Routes>
            <Route path="/auth/callback" element={<SignInCallbackPage />} />
            <Route path="/" element={<PrivateRoute /> }>
              <Route index element={<HomePage />} />
              <Route path="/schedule-management" element={<ScheduleManagementPage />} />
              <Route path="/schedule-management/:scheduleId" element={<ScheduleDetailsPage />} />
              <Route path="/settings" element={<ApiKeysPage />} />
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          </Router>
      </AppTheme>
  );
}

if (isDev) {
  loadDevMessages();
  loadErrorMessages();
}

export default App;
