import * as React from 'react';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import MuiDrawer, { drawerClasses } from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SelectContent from './SelectContent';
import MenuContent from './MenuContent';
import CardAlert from './CardAlert';
import OptionsMenu from './OptionsMenu';

import { getUser } from '../services/authService';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer)({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: 'border-box',
  mt: 10,
  [`& .${drawerClasses.paper}`]: {
    width: drawerWidth,
    boxSizing: 'border-box',
  },
});

export default function SideMenu() {
    const [userProfile, setUserProfile] = React.useState<any>(null);

    React.useEffect(() => {
        const loadUserProfile = async () => {
        const user = await getUser();
        if (user) {
            setUserProfile(user.profile);
        }
        };

        loadUserProfile();
    }, []);

    return (
        <Drawer
        variant="permanent"
        sx={{
            display: { xs: 'none', md: 'block' },
            [`& .${drawerClasses.paper}`]: {
            backgroundColor: 'background.paper',
            },
        }}
        >
        <Stack
            direction="row"
            spacing={1}
            sx={{
                justifyContent: 'center',
                mr: 'auto',
                p: 2,
            }}
        >
            <Box
                sx={{
                width: '2rem',
                height: '2rem',
                bgcolor: 'black',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'center',
                backgroundImage: "url('logo.png')",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                }}
            >
            </Box>
            <Typography variant="h4" component="h1" sx={{ color: 'text.primary' }}>
              Engrate
            </Typography>
        </Stack>
        <Divider />
        <Box
            sx={{
            display: 'flex',
            mt: 'calc(var(--template-frame-height, 0px) + 4px)',
            p: 1.5,
            }}
        >
            <SelectContent />
        </Box>
        <Divider />
        <MenuContent />
        <Stack
            direction="row"
            sx={{
                p: 2,
                gap: 1,
                alignItems: 'center',
                borderTop: '1px solid',
                borderColor: 'divider',
            }}
        >
            <Avatar color="primary">
                {userProfile?.given_name?.charAt(0) || 'U'}{userProfile?.family_name?.charAt(0)}
            </Avatar>

            <Box sx={{ mr: 'auto' }}>
            <Typography variant="body2" sx={{ fontWeight: 500, lineHeight: '16px' }}>
                {userProfile?.given_name} {userProfile?.family_name}
            </Typography>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            {userProfile?.preferred_username}
            </Typography>
            </Box>
            <OptionsMenu />
        </Stack>
    </Drawer>
  );
}
