import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Breadcrumbs, { breadcrumbsClasses } from '@mui/material/Breadcrumbs';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  [`& .${breadcrumbsClasses.separator}`]: {
    color: theme.palette.action.disabled,
    margin: 1,
  },
  [`& .${breadcrumbsClasses.ol}`]: {
    alignItems: 'center',
  },
}));

export default function NavbarBreadcrumbs() {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);

  return (
    <StyledBreadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextRoundedIcon fontSize="small" />}
    >
      <Typography variant="body1" component={Link} to="/" sx={{ textDecoration: 'none', color: 'inherit' }}>
        Engrate
      </Typography>
      {pathSegments.map((segment, index) => {
        const isLast = index === pathSegments.length - 1;

        // Construct the path for the current segment
        const linkTo = '/' + pathSegments.slice(0, index + 1).join('/');

        // Capitalize each word and remove dashes
        const formattedSegment = segment
          .split('-') // Split by dashes
          .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
          .join(' '); // Rejoin with spaces

        return isLast ? (
          <Typography
            key={index}
            sx={{
              color: 'text.primary',
              fontWeight: 600,
            }}
          >
            {formattedSegment}
          </Typography>
        ) : (
          <Typography
            key={index}
            component={Link}
            to={linkTo}
            sx={{
              textDecoration: 'none',
              color: 'text.secondary',
              fontWeight: 400,
            }}
          >
            {formattedSegment}
          </Typography>
        );
      })}
    </StyledBreadcrumbs>
  );
}
