import React from 'react';
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import CancelIcon from '@mui/icons-material/Cancel';

type Status = 'accepted' | 'submitted' | 'pending' | 'partially_accepted' | 'rejected';

interface StatusChipProps {
  status: Status;
}

const StatusChip: React.FC<StatusChipProps> = ({ status }) => {
  const icons: { [key in Status]: JSX.Element } = {
    accepted: <CheckCircleIcon />,
    submitted: <CloudUploadIcon />,
    pending: <HourglassEmptyIcon />,
    partially_accepted: <ThumbUpAltIcon />,
    rejected: <CancelIcon />,
  };

  const colors: { [key in Status]: 'success' | 'primary' | 'default' | 'error' } = {
    accepted: 'success',
    submitted: 'primary',
    pending: 'default',
    partially_accepted: 'success',
    rejected: 'error',
  };

  return (
    <Chip
      variant="outlined"
      size="small"
      icon={icons[status]}
      label={status.replace('_', ' ')} // Replace underscores for better readability
      color={colors[status]}
    />
  );
};

export default StatusChip;
