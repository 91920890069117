import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';

import HighlightedCard from '../components/HighlightedCard';
import StatCard, { StatCardProps } from '../components/StatCard';
import Typography from '@mui/material/Typography';

import Page from '../components/Page';

const data: StatCardProps[] = [
    {
      title: 'API Requests',
      value: '14k',
      interval: 'Last 30 days',
      trend: 'up',
      data: [
        200, 24, 220, 260, 240, 380, 100, 240, 280, 240, 300, 340, 320, 360, 340, 380,
        360, 400, 380, 420, 400, 640, 340, 460, 440, 480, 460, 600, 880, 920,
      ],
    },
    {
      title: 'Accelerated Power',
      value: '325MW',
      interval: 'Last 30 days',
      trend: 'up',
      data: [
        1640, 1250, 970, 1130, 1050, 900, 720, 1080, 900, 450, 920, 820, 840, 600, 820,
        780, 800, 760, 380, 740, 660, 620, 840, 500, 520, 480, 400, 260, 300, 2200,
      ],
    },
    {
      title: 'Assets connected',
      value: '530',
      interval: 'Last 30 days',
      trend: 'neutral',
      data: [
        10, 11, 11, 12, 13, 14, 100, 120, 130, 140, 160, 210, 230, 220, 310, 630,
        520, 410, 530, 520, 610, 530, 520, 610, 530, 420, 510, 430, 520, 510,
      ],
    },
];

const HomePage = () => {

  return (
    <Page>
      <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
        <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
            Home
        </Typography>
        <Grid
          container
          spacing={2}
          columns={12}
          sx={{ mb: (theme) => theme.spacing(2) }}
        >
          {data.map((card, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
            <StatCard {...card} />
          </Grid>
          ))}
          <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
            <HighlightedCard />
          </Grid>
        </Grid>
      </Box>
    </Page>
  )
}

export default HomePage;
