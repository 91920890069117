import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import Typography from '@mui/material/Typography';
import { LineChart } from '@mui/x-charts/LineChart';

import Page from '../components/Page';
import StatusChip from '../components/StatusChip';
import { apiClient } from '../apiClient';
import { version } from 'os';

interface ScheduleDetails {
  uid: string;
  acceptace_level?: string;
  created_ts: string;
  last_modified_ts: string;
  status?: string;
  time_series_uid: string;
  version: number;
  spec: {
    ids: {
      in_area: string;
      out_area: string;
      in_party: string;
      out_party: string;
    };
    type: string;
    data: Array<{ ts: string; amount: number }>;
  };
}

function AreaGradient({ color, id }: { color: string; id: string }) {
  return (
    <defs>
      <linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor={color} stopOpacity={0.5} />
        <stop offset="100%" stopColor={color} stopOpacity={0} />
      </linearGradient>
    </defs>
  );
}

const ScheduleDetailsPage = () => {
  const { scheduleId } = useParams<{ scheduleId: string }>();
  const [scheduleDetails, setScheduleDetails] = useState<ScheduleDetails[]>([]);
  const [error, setError] = useState<string | null>(null);
  const theme = useTheme();

  const colorPalette = [
    theme.palette.primary.light,
    theme.palette.primary.main,
    theme.palette.primary.dark,
  ];

  useEffect(() => {
    if (scheduleId) {
      apiClient(`schedule-series/${scheduleId}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data: ScheduleDetails[]) => {
          setScheduleDetails(data);
        })
        .catch((err) => {
          setError(err.message);
        });
    }
  }, [scheduleId]);

  const colorMap: { [key: string]: string } = {
    production: 'hsl(120, 61%, 50%)', // Green
    consumption: 'hsl(30, 100%, 50%)', // Orange
    internal: 'hsl(200, 70%, 50%)',    // Blue
    external: 'hsl(280, 60%, 50%)',    // Purple
  };

  return (
    <Page>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Schedule Details for schedule {scheduleId}
      </Typography>
      {error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <Grid container spacing={2}>
          {scheduleDetails.map((schedule, index) => {
            const timestamps = schedule.spec.data.map((item) => item.ts);
            const amounts = schedule.spec.data.map((item) => item.amount);
            const totalAmount = schedule.spec.data.reduce((sum, item) => sum + item.amount, 0);
            const gradientColor = colorMap[schedule.spec.type];

            return (
              <Grid item xs={12} md={6} key={schedule.uid}>
                <Card variant="outlined">
                  <CardContent>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      spacing={2}
                      sx={{ mb: 2 }}
                    >
                      <Box sx={{ flex: 1 }}>
                        <Typography 
                          variant="h4" 
                          gutterBottom 
                          sx={{ color: gradientColor }}
                        >
                          {schedule.spec.type.charAt(0).toUpperCase() + schedule.spec.type.slice(1)}
                        </Typography>

                        <Stack 
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          sx={{ mb: 1 }}
                        >
                          <Typography variant="h4" component="p">
                            {totalAmount} MW
                          </Typography>
                          <StatusChip status={schedule.status as 'accepted' | 'submitted' | 'pending' | 'partially_accepted' | 'rejected'} />
                        </Stack>

                        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                          Timeseries UID: {schedule.time_series_uid}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          minWidth: '250px',
                          borderRadius: 1,
                          border: '1px solid hsla(220, 20%, 25%, 0.6)',
                          backgroundColor: 'background.paper',
                          overflow: 'hidden',
                        }}
                      >
                        <Table size="small">
                          <TableBody>
                            <TableRow>
                              <TableCell variant="head">In Area</TableCell>
                              <TableCell>{schedule.spec.ids.in_area}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell variant="head">Out Area</TableCell>
                              <TableCell>{schedule.spec.ids.out_area}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell variant="head">In Party</TableCell>
                              <TableCell>{schedule.spec.ids.in_party}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell variant="head">Out Party</TableCell>
                              <TableCell>{schedule.spec.ids.out_party}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Box>
                    </Stack>

                    <LineChart
                      colors={colorPalette}
                      xAxis={[
                        {
                          scaleType: 'point',
                          data: timestamps,
                          tickInterval: (index, i) => (i + 1) % 5 === 0,
                        },
                      ]}
                      series={[
                        {
                          id: `chart-${index}`,
                          label: `Version ${schedule.version}`,
                          showMark: false,
                          curve: 'linear',
                          data: amounts,
                          area: true,
                          stackOrder: 'ascending',
                        },
                      ]}
                      height={250}
                      margin={{ left: 50, right: 20, top: 20, bottom: 20 }}
                      grid={{ horizontal: true }}
                      sx={{
                        [`& .MuiAreaElement-series-chart-${index}`]: {
                          fill: `url(#chart-${index})`,
                        },
                        [`& .MuiLineElement-series-chart-${index}`]: {
                          stroke: gradientColor,
                        },
                      }}
                      slotProps={{
                        legend: {
                          hidden: true,
                        },
                      }}
                    >
                      <defs>
                        <linearGradient id={`chart-${index}`} x1="50%" y1="0%" x2="50%" y2="100%">
                          <stop offset="0%" stopColor={gradientColor} stopOpacity={0.5} />
                          <stop offset="100%" stopColor={gradientColor} stopOpacity={0} />
                        </linearGradient>
                      </defs>
                    </LineChart>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Page>
  );
};

export default ScheduleDetailsPage;
