import React,  { useEffect } from 'react';
import { getAccessToken } from './services/authService';
import config from './config';

// Custom fetch wrapper
export const apiClient = async (endpoint: string, options: RequestInit = {}) => {
    try {
        const token = await getAccessToken();

        if (!token) {
            throw new Error('Access token not found. User might not be logged in.');
        }

        const headers = {
            ...options.headers,
            Authorization: `Bearer ${token}`,
        };

        const response = await fetch(`${config.apiBaseUrl}/${endpoint}`, {
            ...options,
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return response; // Parse JSON response if expected
    } catch (error) {
        console.error('API Client Error:', error);
        throw error; // Re-throw the error for further handling
    }
};
